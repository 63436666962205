









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SvgIcon extends Vue {
  @Prop({required: true})
  icon!: string;

  @Prop({default: false, type: Boolean})
  fixedWidth!: boolean;
}
