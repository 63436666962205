































import { ToastService } from '@/services/ToastService';
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Toast extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ required: true })
  id !: string;

  @Prop({ default: 'success' })
  variant !: string;

  onClose() {
    ToastService.hideToast(this.id);
  }

  get icon (): string[] {
    if (this.variant === 'success') {
      return ['fas', 'check']
    } else if (this.variant === 'danger') {
      return ['fas', 'exclamation-triangle']
    } else {
      return ['fas', 'info-circle']
    }
  }

  get classes () : string {
    const c = []
    c.push(`bg-${this.variant}`)
    if (this.show) {
      c.push('show')
    }
    return c.join(' ')
  }
}
