


























import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/components/Modal/Modal.vue'

@Component({ 
  components:{ 
    Modal
  }
})
export default class HelpIcon extends Vue {
  @Prop({default: ''})
  title!: string;

  onHelpIconClick() { 
    (this.$refs.modal as Modal).show();
  }
}
