







































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Modal as BModal } from 'bootstrap'
import FButton from '@/components/Button/FButton.vue'
import shortUUID from 'short-uuid';
import { ModalService } from '@/services/ModalService';

@Component({
  components: {
    FButton
  }
})
export default class Modal extends Vue {
  ref: BModal | null = null;
  elementRef: Element | null = null;

  @Prop({ default: '' })
  title!: string;

  @Prop({ default: false, type: Boolean})
  hideOkBtn!: boolean;

  @Prop({ default: false, type: Boolean})
  hideCancelBtn!: boolean;

  @Prop({ default: false, type: Boolean})
  hideXBtn!: boolean;

  @Prop({ default: false, type: Boolean})
  hideFooter!: boolean;

  @Prop({ default: false, type: Boolean})
  protectCloseAction!: boolean;

  @Prop({ default: 'Submit'})
  okTitle!: string;

  @Prop({ default: 'Cancel'})
  cancelTitle!: string;

  @Prop({ default: 'secondary'})
  cancelVariant!: string;

  @Prop({ default: 'primary'})
  okVariant!: string;

  @Prop({ default: ''})
  size!: string;

  @Prop({ default: false, type: Boolean})
  allowNesting!: boolean;

  id = shortUUID.generate();
  visible = false;
  ignoreEvent = false;
  index = 0;

  setVisible(visible: boolean) {
    this.visible = visible;

    this.ignoreEvent = true;
    if (this.visible){
      this.ref?.show();
    }else{

      this.ref?.hide();
    }
  }

  get modalSize(){
    if (!this.size){
      return '';
    }
    return `modal-${this.size}`;
  }
  mounted(){
    this.elementRef = this.$refs.modal as Element;
    //moves modal to end of body to help prevent nested elements
    document.body.appendChild(this.elementRef);
    this.ref = new BModal(this.elementRef, {
      backdrop: false,
      keyboard: !this.protectCloseAction,
    });
    this.elementRef.addEventListener('show.bs.modal', this.onShow);
    this.elementRef.addEventListener('hidden.bs.modal', this.onHidden);
  }

  beforeDestroy(){
    (this.$refs.modal as Element).removeEventListener('hidden.bs.modal', this.onHidden);
    (this.$refs.modal as Element).removeEventListener('show.bs.modal', this.onShow);
    this.elementRef?.remove();
  }

  onOk() {
    this.$emit('ok');
  }

  onCancel() {
    if (!this.protectCloseAction){
       this.hide();
    }
    this.$emit('cancel');
  }

  show(){
    this.visible = true;
    this.ref?.show();
  }

  hide(){
    this.visible = false;
    this.ref?.hide();
  }

  onShow(){
    this.visible = true;
    if (!this.ignoreEvent){
      this.index = ModalService.onOpen(this.id, this.setVisible, this.allowNesting);
    }
    this.ignoreEvent = false;
  }

  onHidden(){
    if (!this.ignoreEvent){
      this.visible = false;
      ModalService.onClose(this.id);
    }
    this.ignoreEvent = false;
    this.$emit('hidden');
  }

  scrollToTop(){
    (this.$refs.modal as HTMLElement).scrollTo({top: 0, behavior: 'smooth'});
  }
}
