































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FButton extends Vue {
  @Prop({ default: 'primary' })
  variant !: string;

  @Prop({ default: '' })
  size !: string;

  @Prop({ default: '' })
  href !: string;

  @Prop({ default: null })
  type !: string | null;

  @Prop({ default: null })
  to !: string | null;

  @Prop({ default: false, type: Boolean })
  outline !: boolean;

  @Prop({ default: ''})
  utility !: string;

  @Prop({ default: false, type: Boolean })
  expanded !: boolean;

  @Prop({ default: false, type: Boolean })
  download !: boolean;

  @Prop({ default: '' })
  downloadName !: string;

  @Prop({ default: null })
  beforeIcon !: string[] | null;

  @Prop({ default: null })
  afterIcon !: string[] | null;

  get btnClasses () : string {
    const classesArray: string[] = []

    if (this.utility) {
      if (this.utility === '1'){
        classesArray.push('btn-utility')
        classesArray.push('btn-primary')
      }
      else if (this.utility === '2') {
        classesArray.push('btn-utility-2')
      }
    } else if (!this.outline) {
      classesArray.push(`btn-${this.variant}`)
    } else {
      classesArray.push(`btn-outline-${this.variant}`)
    }

    if (this.expanded) {
      classesArray.push('btn-expanded')
    }

    if (this.size) {
      classesArray.push(`btn-${this.size}`)
    }

    return classesArray.join(' ')
  }
}
