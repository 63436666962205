


















import { Component, Vue } from 'vue-property-decorator'
import Modal from '@/components/Modal/Modal.vue';
import FCheckbox from '@/components/Form/FCheckbox.vue';
import { PaymentSettingsService } from '@/services/PaymentSettingsService';
import { ProfileService } from '@/services/ProfileService';

@Component({ 
  components:{ 
    Modal,
    FCheckbox
  }
})
export default class DirectDepositNoticeModal extends Vue {
  _dontShowAgain = false;

  mounted(){ 
    if (!ProfileService.profile){ 
      return;
    }

    if (!ProfileService.enableDirectDeposit){ 
      return;
    }

    if (PaymentSettingsService.directDepositStatus !== 'NOT-YET-SETUP'){
      this.$cookies.remove('noDDPrompt');
      this.$cookies.remove('clickedSetupDirectDeposit');
      return;
    }

    this._dontShowAgain = !!parseInt(this.$cookies.get('noDDPrompt'));
    if (this._dontShowAgain){ 
      return;
    }

    const clickedAlready = this.$cookies.get('clickedSetupDirectDeposit');
    if (parseInt(clickedAlready)){ 
      return;
    }

    this.$cookies.set('clickedSetupDirectDeposit','1', '1d');
    (this.$refs.modal as Modal).show();
  }

  get dontShowAgain() { 
    return this._dontShowAgain;
  }

  set dontShowAgain(val: boolean) { 
    if (val){ 
      this.$cookies.set('noDDPrompt', '1', '182d')
    }else{ 
      this.$cookies.remove('noDDPrompt');
    }
    this._dontShowAgain = val;
  }

  onOk() {
    this.$router.push('/payment/direct-deposit');
    (this.$refs.modal as Modal).hide();
  }
}
