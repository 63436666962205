import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faAngleDown,
  faAngleRight,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faBan,
  faBars,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faCircle,
  faClipboardList,
  faClock,
  faDownload,
  faEdit,
  faFile,
  faFileAlt,
  faFileCsv,
  faFileInvoiceDollar,
  faFilePdf,
  faFileSearch,
  faFolderOpen,
  faGripLines,
  faHome,
  faInfoCircle,
  faLongArrowLeft,
  faMoneyCheck,
  faMoneyCheckEdit,
  faPlus,
  faQuestionCircle,
  faSearch,
  faShoppingCart,
  faSignOut,
  faSync,
  faTh,
  faTrash,
  faTrashAlt,
  faSave,
  faUserCircle,
  faChartLine,
  faUndo,
  faRedo,
  faArrowLeft,
  faPencil,
  faExpand,
  faCompress,
  faTable,
} from '@fortawesome/pro-light-svg-icons'

import {
  faCheckCircle as faCheckCircleSolid,
  faShoppingCart as faShoppingCartSolid,
  faCheck as faCheckSolid,
  faMoneyCheck as faMoneyCheckSolid,
  faInfoCircle as faInfoCircleSolid,
  faExclamationTriangle,
  faTimes,
  faCaretDown,
  faBullhorn,
  faCaretLeft,
  faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons'


class FontAwesomeServiceClass{
  init(){
    library.add(
      faChevronRight,
      faChevronLeft,
      faChevronUp,
      faPlus,
      faCheckCircleSolid,
      faBan,
      faInfoCircle,
      faShoppingCart,
      faShoppingCartSolid,
      faShoppingCart,
      faCheck,
      faCheckSolid,
      faMoneyCheck,
      faMoneyCheckEdit,
      faFileInvoiceDollar,
      faFileSearch,
      faDownload,
      faClipboardList,
      faMoneyCheckSolid,
      faQuestionCircle,
      faExclamationTriangle,
      faInfoCircleSolid,
      faTimes,
      faChevronDown,
      faAngleDown,
      faAngleRight,
      faAngleLeft,
      faAngleDoubleRight,
      faAngleDoubleLeft,
      faUserCircle,
      faSync,
      faSignOut,
      faBullhorn,
      faSearch,
      faHome,
      faTh,
      faBars,
      faFile,
      faEdit,
      faGripLines,
      faQuestionCircle,
      faFileAlt,
      faFileCsv,
      faFilePdf,
      faCaretLeft,
      faTrash,
      faTrashAlt,
      faCheckCircle,
      faCircle,
      faFolderOpen,
      faLongArrowLeft,
      faCaretDown,
      faClock,
      faSave,
      faChartLine,
      faUndo,
      faRedo,
      faSpinnerThird,
      faArrowLeft,
      faPencil,
      faExpand,
      faCompress,
      faTable
    )
  }
}

export const FontAwesomeService = new FontAwesomeServiceClass();
