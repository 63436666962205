











import { Component, Vue } from 'vue-property-decorator'
import { BusyService } from '@/services/BusyService';

@Component
export default class BusyOverlay extends Vue {
  store = BusyService;
}
