














import { Component, Vue } from 'vue-property-decorator'
import Toast from '@/components/Toast/Toast.vue'
import { ToastService } from '@/services/ToastService';

@Component({
  components: {
    Toast
  }
})
export default class Toasts extends Vue {
  store = ToastService;
}
