














































import { Component, Vue } from 'vue-property-decorator'
import CCLILogoWhite from '@/components/CCLILogo/CCLILogoWhite.vue'
import FSelect from '@/components/Form/FSelect.vue'
import { SelectOption } from '@/models/SelectOption'
import { ProfileService } from '@/services/ProfileService'
import { TranslationService } from '@/services/TranslationService'

@Component({
  components: {
    CCLILogoWhite,
    FSelect
  }
})
export default class SiteFooter extends Vue {
  get version() { 
    return process.env.VUE_APP_BUILD_VERSION;
  }

  get footerLinks() { 
    return [
      { to: '/terms-of-use', label: this.$t('G.LIT_TermsOfUse').toString() },
      { url: ProfileService.profile?.org.privacyPolicyUrl, label: this.$t('G.LIT_PrivacyPolicy').toString() },
      { to: '/contact', label: this.$t('G.LIT_Contact').toString() }
    ]
  }

  get cultureOptions(): SelectOption[] {
    return TranslationService.availableLanguages.map( l => ({
      value: l.code, 
      label: l.label
    }))
  }

  translationService = TranslationService;

  onTranslationInput() { 
    this.translationService.setLanguage(this.translationService.language);
  }

  get currentYear (): string {
    return (new Date()).getFullYear().toString()
  }
}
