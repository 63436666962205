




































import { Component, Vue } from 'vue-property-decorator'
import FButton from '@/components/Button/FButton.vue'
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'
import { SiteBannerService } from '@/services/SiteBannerService';
import { Collapse } from 'bootstrap'

@Component({
  components: {
    FButton, 
    SvgIcon
  }
})
export default class SiteBanner extends Vue {
  store = SiteBannerService;
  mounted () {
    const ref = new Collapse(this.$refs.collapse as Element, { toggle: false })
    SiteBannerService.init(ref);
  }
  onClick(){ 
    SiteBannerService.hide();
    if (this.store.onAction){
      this.store.onAction();
    }
  }
}
