

































































































import { Component, Vue } from 'vue-property-decorator'
import SiteNavItem from '@/components/SiteNav/SiteNavItem.vue'
import SiteNavTitle from '@/components/SiteNav/SiteNavTitle.vue'
import { MobileService } from '@/services/MobileService';
import { ProfileService } from '@/services/ProfileService';
import { DownloadService } from '@/services/DownloadService';

@Component({
  components: {
    SiteNavItem,
    SiteNavTitle
  }
})
export default class SiteNav extends Vue {
  mobileService = MobileService;

  get newDownloadsCount() {
    return DownloadService.downloadCounts;
  }

  get showMobile(){
    return MobileService.showMobileNav;
  }


  get profile() {
    return ProfileService.profile;
  }

  get showReportTab(){
    // update when implementing earnings bi report
    return this.profile?.featureToggleDashboard && this.profile?.featureToggleRankingReports;
  }

  get showEarningsDashboard(){
    return this.profile?.featureToggleRoyalty && this.profile?.featureToggleDashboard || false;
  }

  get showPaymentsTab(){
    return this.profile?.featureTogglePayments;
  }

  get showRoyaltyInfo(){
    return this.profile?.featureToggleRoyalty;
  }

  get showIntellectualPropertyTab(){
    return this.profile?.featureToggleManageSongs;
  }

}
